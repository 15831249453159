import '../styles/global.css'
import { Auth0Provider } from '@auth0/auth0-react';
import { useEffect, useState } from 'react';

const App = ({ Component, pageProps}) => {
    const redirectUri = `${process.env["NEXT_PUBLIC_BASE_URL"]}/success`
    const [vconsoleLoaded, setVconsoleLoaded] = useState(false)

    useEffect(async () => {
      if (process.env['NEXT_PUBLIC_VCONSOLE_ENABLED'] === '1') {
        if (!vconsoleLoaded) {
          const VConsole = (await import('vconsole')).default
          const vConsole = new VConsole({ theme: 'dark' });
          setVconsoleLoaded(true)
          return () => vConsole.destroy()
        }
      }
    }, [])

    return (
        <Auth0Provider
            domain={process.env["NEXT_PUBLIC_AUTH0_DOMAIN"]}
            clientId={process.env["NEXT_PUBLIC_AUTH0_CLIENT_ID"]}
            redirectUri={redirectUri}
        >
            <Component {...pageProps} />
        </Auth0Provider>
    )
}

export default App
